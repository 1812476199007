import { FC, useState, useEffect } from 'react';
import { Card, Col, Dropdown, Image, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import { DateTime } from 'luxon';
import { CarouselModal } from '../../../Modals/AddArticles/CarouselModal';
import UserIcon from '../../../../assets/user.svg';
import DeleteIcon from '../../../../assets/delete-icon.svg';
import EditblackIcon from '../../../../assets/edit-icon.svg';
import ArchiveIcon from '../../../../assets/archive.svg';
import DraftIcon from '../../../../assets/draft.svg';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../../modules/hooks';
import {
  Article,
  ArticleResponse,
  Categories,
  Media,
} from '../../../../modules/interfaces';
import { endpoints, routes } from '../../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../../modules/utils/transform';
import { DotDropDown } from '../../../Customize/DropDown';
import './styles/styles.css';
import Header from '../../../Header';
import AddArticles from '../../../Modals/AddArticles/AddArticles';
import DeleteModal from '../../../Modals/DeleteModal/DeleteModal';
import { ArticleStatus } from '../../../../modules/enums/status';

interface RowMediaData {
  rowData: Media[];
  key: string;
}

interface ArticlesDetailsProps {
  handleClose?: () => void;
  articleData?: Article;
  subpage: boolean;
}

export const ArticlesDetails: FC<ArticlesDetailsProps> = ({
  handleClose,
  articleData,
  subpage,
}) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState<boolean>(false);
  const [carouselModal, setCarouselModal] = useState<boolean>(false);
  const [updatedStateFlag, setUpdatedStateFlag] = useState<boolean>(false);
  const [categories, setCategories] = useState<Categories[]>([]);
  const [addArticle, setAddArticle] = useState(false);
  const [editData, setEditData] = useState<Article>();
  const [previewImages, setPreviewImages] = useState<Media[]>([]);
  const [loadImages, setLoadImages] = useState<Media[]>([]);
  const [articleId, setArticleId] = useState<string>();
  const location = useLocation();
  const { setSimpleToasts } = useNotifications()!;

  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [{ data: categoriesData, loading: categoriesLoading, error: categoriesError }] =
    useRequest<Categories[]>(endpoints.CATEGORIES, 'get', {
      authToken: credentialsInfo?.token,
    });

  useEffect(() => {
    if (categoriesError) {
      const message = getApiErrorMessage(categoriesError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (categoriesData) {
      const { result } = categoriesData;
      setCategories(result);
    }
  }, [categoriesLoading, categoriesData, categoriesError, setSimpleToasts, setLoading]);

  const url = `${endpoints.ARTICLES}/${articleId}`;
  const [{ data, loading, error }, getDetail] = useRequest<Article>(
    url,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const deleteURL = `${endpoints.ARTICLES}/${editData?.uuid}`;
  const [
    { data: deleteResult, loading: deleteLoading, error: deleteError },
    deleteArticle,
  ] = useRequest<ArticleResponse>(
    deleteURL,
    'delete',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const renderTagsItem = (item: string) => {
    return <div className="tag-text-view">{item}</div>;
  };
  const [{ data: patchResult, loading: patchLoading, error: patchError }, updatePatch] =
    useRequest(
      url,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  function estimateReadingTime(bodyLength: number) {
    const wordsPerMinute = 200;

    const readingTimeInMinutes = bodyLength / wordsPerMinute;

    if (readingTimeInMinutes < 1) {
      const seconds = Math.ceil(readingTimeInMinutes * 60);
      return `${seconds} sec read`;
    }

    return `${Math.ceil(readingTimeInMinutes)} min read`;
  }

  const onDeleteArticle = () => {
    deleteArticle();
  };
  const onChangeStatus = (status: string) => {
    setUpdatedStateFlag(true);
    const formData = new FormData();
    formData.append('status', status);

    updatePatch({
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: credentialsInfo?.token,
      },
    });
  };

  useEffect(() => {
    setLoading(loading || patchLoading);
  }, [loading, patchLoading, setLoading]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (patchError) {
      const message = getApiErrorMessage(patchError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (patchResult && updatedStateFlag) {
      getDetail();
      setUpdatedStateFlag(false);
    }
    if (data) {
      const { result } = data;

      setEditData(result);
      if (result?.media.length > 0) {
        setPreviewImages(result.media.slice(1, 5));
        setLoadImages(result.media.slice(4));
      }
    }
  }, [loading, data, error, patchError, patchResult, setSimpleToasts, setLoading]);

  useEffect(() => {
    if (articleData) {
      setEditData(articleData);
    }
    if (!subpage) {
      const searchString = location.search;
      const params = new URLSearchParams(searchString);
      setArticleId(params.get('id') || '');
      getDetail();
      const formData = new FormData();
      formData.append('lastViewedDate', 'true');
      updatePatch({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    }
  }, [credentialsInfo?.token, articleData, subpage, getDetail, updatePatch, location]);

  useEffect(() => {
    if (!deleteResult) return;
    navigate(routes.ARTICLES);
    setModal(false);
  }, [deleteResult]);

  return (
    <Container fluid className="article-detail-container p-0">
      <Header title="Article details" isBack />
      <Card className="article-body">
        <Row className="article-detail-header justify-content-between align-items-center">
          <div className="detaile-main-container-info">
            <div className="detaile-main-container">
              <div className="three-dot-and-culture-text-container">
                <div className="tag-arry-style">
                  {editData?.categories.map(item => renderTagsItem(item.name))}
                </div>
                {true ? (
                  <DotDropDown title="">
                    {editData?.status === ArticleStatus.DRAFT && (
                      <Dropdown.Item
                        onClick={() => {
                          setAddArticle(true);
                        }}
                      >
                        <img
                          className="edit-icon-image-style"
                          src={EditblackIcon}
                          alt="edit-black-icon"
                        />
                        <div className="edit-text-style">Edit</div>
                      </Dropdown.Item>
                    )}

                    {editData?.status !== ArticleStatus.ARCHIVED && (
                      <Dropdown.Item
                        onClick={() => onChangeStatus(ArticleStatus.ARCHIVED)}
                      >
                        <img
                          className="edit-icon-image-style"
                          src={ArchiveIcon}
                          alt="edit-black-icon"
                        />
                        <div className="edit-text-style">Move to Archive</div>
                      </Dropdown.Item>
                    )}

                    {editData?.status !== ArticleStatus.DRAFT && (
                      <Dropdown.Item onClick={() => onChangeStatus(ArticleStatus.DRAFT)}>
                        <img
                          className="edit-icon-image-style"
                          src={DraftIcon}
                          alt="edit-black-icon"
                        />
                        <div className="edit-text-style">Move to Draft</div>
                      </Dropdown.Item>
                    )}

                    {editData?.status !== ArticleStatus.PUBLISHED && (
                      <>
                        <Dropdown.Item
                          onClick={() => onChangeStatus(ArticleStatus.PUBLISHED)}
                        >
                          <img
                            className="edit-icon-image-style"
                            src={DraftIcon}
                            alt="edit-black-icon"
                          />
                          <div className="edit-text-style">Move to Published</div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setModal(true)}>
                          <img
                            className="edit-icon-image-style"
                            src={DeleteIcon}
                            alt="edit-black-icon"
                          />
                          <div className="delete-text-style">Delete</div>
                        </Dropdown.Item>
                      </>
                    )}
                  </DotDropDown>
                ) : null}
              </div>

              <div className="art-and-culture-main-text">{editData?.name}</div>

              <div className="image-text-container">
                <img className="art-and-clture-name-view" alt="user" src={UserIcon} />

                <div>
                  <div className="name-text-style">{`${editData?.createdBy.firstName} ${editData?.createdBy.lastName}`}</div>
                  <div className="date-and-readimg-time">
                    <div className="date-and-time-text-style">
                      Last updated{' '}
                      {DateTime.fromISO(editData?.updatedAt || '').toFormat(
                        'dd LLLL, yyyy hh:mm a',
                      )}
                    </div>
                    <div className="dot-and-time-container">
                      <div className="dot-style" />
                      <div className="time-reading-view">
                        {estimateReadingTime(editData?.body.split(/\s+/).length || 0)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Row className="article-detail-content">
                <span className="pr-0 pl-0">{editData ? parse(editData.body) : ''}</span>
              </Row>
              {editData?.media && editData?.media.length > 0 && (
                <Row>
                  <Col xs={6} md={6} className="p-2 pt-0">
                    <Image
                      alt=""
                      src={editData?.media[0].uri}
                      className="article-main-image"
                    />
                  </Col>

                  <Col xs={6} md={6} className="p-2 pt-0">
                    <Row>
                      {previewImages.length > 0 &&
                        previewImages.map((images, index) => {
                          if (index === 3 && loadImages.length > 1) {
                            return (
                              <Col
                                xs={6}
                                md={6}
                                className="p-2 pt-0 position-relative"
                                style={{ cursor: 'pointer' }}
                              >
                                <Image
                                  alt=""
                                  src={images?.uri}
                                  className="article-last-image"
                                  onClick={() => setCarouselModal(true)}
                                />

                                <div className="image-text">+ Load more images</div>
                              </Col>
                            );
                          }
                          return (
                            <Col xs={6} md={6} className="p-2 pt-0">
                              <Image
                                alt=""
                                src={images?.uri}
                                className="article-more-image"
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  </Col>
                </Row>
              )}

              <Row style={{ marginTop: '20px' }}>
                <div className="tags-text-style">Tags</div>
                <div className="tag-arry-style">{editData?.tags.map(renderTagsItem)}</div>
              </Row>
            </div>
          </div>
        </Row>

        <Row className="justify-content-between align-items-center article-detail-footer d-none">
          <span className="w-auto">12/10/2022 2:35PM</span>
          <span className="w-auto">15 min read</span>
        </Row>
      </Card>
      <AddArticles
        article={editData}
        show={addArticle}
        key={JSON.stringify(editData)}
        categories={categories}
        onSave={() => setAddArticle(false)}
        onHide={() => setAddArticle(false)}
        refetch={getDetail}
      />
      {loadImages.length > 0 && (
        <CarouselModal
          show={carouselModal}
          handleClose={() => {
            setCarouselModal(false);
          }}
          additionalImages={loadImages}
        />
      )}

      {editData?.uuid && (
        <DeleteModal
          show={modal}
          onDelete={onDeleteArticle}
          onHide={() => setModal(false)}
          title="Delete Article"
          description="Are you sure you want to delete this article?"
        />
      )}
    </Container>
  );
};
